@use "../abstracts/" as *;

.card {
  background-size: cover;
  @include centerItem;
  position: relative;
  margin-top: 40px;
  border: $calendarWindow-border-color;
  border-radius: 10%;
  text-align: center;
  cursor: pointer;

  h3 {
    color: $calendarWindow-text-color;
    font-size: 20px;
    -webkit-text-stroke: 0.5px #020202 ;
  }
}
