@use "../abstracts/" as *;

.calendarPage-container {
  width: 100%;
  height: 100vh;

  .calendarPage-background {
    background-image: url("../../assets/images/MyFeldt_Friends_Closed.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: clamp(0.5rem, 4vw, 15rem);
    padding-left: clamp(0.5rem, 4vw, 15rem);
    background-position-y: 70%;

    @include mq(web) {
      width: 1800px;
      min-height: 100vh;
      background-size: cover;
      padding-top: 0;
      padding-left: 0;
      background-position-y: 100%;
    }
    @include mq(tablet) {
      width: 1800px;
      background-image: url("../../assets/images/MyFeldt_Friends_Closed.svg");
      min-width: 105vh;
      padding-left: clamp(0.5rem, 2vw, 15rem);
      background-position-y: 100%;

    }
    @include mq(mobile) {
      width: 1450px;
      height: 100vh;
      background-size: cover;
    }
  }

  .calendar {
    display: grid;
    grid-template-columns: repeat(8, auto);

    .calendarCard {
      margin: 5px;

      &:first-of-type {
        margin-top: 20px;
      }
      &:nth-of-type(2) {
        margin-top: 20px;
        margin-left: 5px;
      }
      &:nth-of-type(3) {
        margin-top: 45px;
        margin-left: 5px;
      }
      &:nth-of-type(4) {
        margin-top: 45px;
        margin-left: 5px;
      }
      &:nth-of-type(5) {
        margin-top: 45px;
      }
      &:nth-of-type(6) {
        margin-top: 5px;
      }
      &:nth-of-type(7) {
        margin-top: 45px;
        margin-left: 5px;
      }
      &:nth-of-type(8) {
        margin-top: 45px;
        margin-left: -50px;
      }
      &:nth-of-type(9) {
        margin-left: 20px;
      }
      &:nth-of-type(10) {
        margin-top: 20px;
        margin-left: 20px;
      }
      &:nth-of-type(11) {
        margin-top: -40px;
        margin-left: -10px;
      }
      &:nth-of-type(12) {
        margin-top: -30px;
        margin-left: 40px;
      }
      &:nth-of-type(13) {
        margin-top: -60px;
        margin-left: -40px;
      }
      &:nth-of-type(14) {
        margin-top: -70px;
        margin-left: -20px;
      }
      &:nth-of-type(15) {
        margin-top: -20px;
        margin-left: 30px;
      }
      &:nth-of-type(16) {
        margin-top: -33px;
        margin-left: 0px;
      }
      &:nth-of-type(17) {
        margin-left: 20px;
        margin-top: 10px;
      }
      &:nth-of-type(18) {
        margin-top: -40px;
        margin-left: -10px;
      }
      &:nth-of-type(19) {
        margin-top: -60px;
        margin-left: -10px;
      }
      &:nth-of-type(20) {
        margin-left: 40px;
      }
      &:nth-of-type(21) {
        margin-top: -150px;
        margin-left: -40px;
      }
      &:nth-of-type(22) {
        margin-top: -65px;
        margin-left: -20px;
      }
      &:nth-of-type(23) {
        margin-top: -30px;
        margin-left: 15px;
      }
      &:nth-of-type(24) {
        margin-top: -50px;
        margin-left: 5px;
      }
    }
  }
}
