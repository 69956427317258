@use '../abstracts/' as *;
@font-face {
  font-family: 'FriendsCapitaine'; /* Define a unique name for the font */
  src: url('../../assets/Fonts/FriendsCapitaine-Regular.otf') format('opentype'); /* Specify the path to your font file */
}
.login-background {
  background-image: url("../../assets/images/LoginPage/Login_MyFeldt_Friends_SK23.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: fixed;
  height: 100%;
  width: 100%;

  .left-overlay {
    width: 50%;
    background-color: rgba($light-background-color-overlay, 0.8); /* Adjust the alpha value (0.8 in this example) */
    height: 100vh;

    @include mq(tablet) {
      height: 100%;
      width: 100%;
    }
  }

  @include mq(tablet) {
    background-image: url("../../assets/images/LoginPage/Login_MyFeldt_Friends_SK23.svg");
  }

  @include mq(mobile) {
    background-image: url("../../assets/images/LoginPage/Login_MyFeldt_Friends_SK23.svg");
  }

  .content {
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(tablet) {
      justify-content: center;
      height: 70vh;
    }

    @include mq(mobile) {
      height: 70vh;
    }

    img {
      width: 30vw;

      @include mq(tablet) {
        width: 60vw;
      }

      @include mq(mobile) {
        width: 80vw;
      }
    }

    h2 {
      font-size: clamp(1rem, 2vw, 6rem);
      color: $dark-brown-color;

      @include mq(tablet) {
        font-size: clamp(1rem, 5vw, 8rem);
      }
    }

    .sign-in-container {
      display: flex;
      flex-direction: column;
      margin-top: 25%;

      @include mq(tablet) {
        margin-top: 15%;
      }

      @include mq(mobile) {
        margin-top: 15rem;
      }

      input {
        width: 30vw;
        padding: 12px 20px;
        margin: 8px 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid $dark-brown-color;
        color: $dark-brown-color;
        font-size: clamp(1rem, 3.5vw, 2rem);
        background-color: transparent;
        text-align: center;

        &::placeholder {
          color: $dark-brown-color;
        }

        &:focus {
          outline: none;
        }

        @include mq(tablet) {
          width: 40vw;
        }

        @include mq(mobile) {
          width: 50vw;
        }
      }

      .error-message {
        color: red;
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
      }

      button {
        font-size: 28px;
        color: $dark-brown-color;
        font-weight: bolder;
        height: 50px;
        border-radius: 5px;
        border: 3px solid $dark-brown-color;
        background: transparent;
        margin-top: 1rem;

        &:hover {
          background-color: $light-background-color-overlay;
        }
      }

      .copyright-text {
        font-family: 'FriendsCapitaine'; /* Use the font family name defined in step 1 */
        position: absolute;
        bottom: 0px; /* Adjust this value to control the distance from the bottom */
        left: 0px; /* Adjust this value to control the distance from the left */
        color: #030303; /* Your desired text color */
        font-size: 14px; /* Your desired font size */

        @include mq(tablet) {
          width: clamp(1rem, 16vw, 10rem);
          position: absolute;
          left: 2%;
          top: 90%;
          height: 4rem;
          width: auto;
        }

        @include mq(mobile) {
          width: clamp(4rem, 10vw, 10rem);
          position: absolute;
          left: 4%;
          top: 93%;
          height: 3rem;
          width: auto;
        }
      }
    }
  }
}