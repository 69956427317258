$lighter-background-color: hsl(36, 35%, 46%);
$light-brown-color: hsl(41, 21%, 41%);
$dark-brown-color: hsl(42, 21%, 33%);
$dark-green-color: hsl(113, 28%, 21%);

$light-background-color-overlay: hsla(56, 89%, 96%, 0.5);
$modal-light-background-color-overlay: hsla(0, 0%, 100%, 0.6);
$light-background-color-overlay-modal: hsla(97, 10%, 84%, 0.82);

//Font sizes
$text-size: clamp(0.8rem, 1.2vw, 2rem);
$modal-text-size: clamp(1.2rem, 1.6vw, 2rem);

$calendarWindow-border-color: 1px solid hsla(0, 0%, 0%, 0.8);
//$calendarWindow-text-color: hsl(41, 32%, 21%);
$calendarWindow-text-color: hsl(40, 43%, 99%);

//media query tablet font size
$text-size-tablet: clamp(1rem, 2.1vw, 1.5rem);
$text-h2-size-tablet: clamp(1rem, 3.2vw, 2rem);
$button-text-size: clamp(1rem, 2vw, 2rem);

//media query mobile font size
$text-size-mobile: clamp(0.9rem, 1.5vw, 1.5rem);
$text-h2-size-tablet: clamp(0.8rem, 4vw, 2rem);
$button-text-size: clamp(1rem, 1.5vw, 2rem);
